import request from '@/utils/request'


// 查询开店套餐列表
export function listShopThali(query) {
  return request({
    url: '/shop/shop/thali/list',
    method: 'get',
    params: query
  })
}

// 查询开店套餐分页
export function pageShopThali(query) {
  return request({
    url: '/shop/shop/thali/page',
    method: 'get',
    params: query
  })
}

// 查询开店套餐详细
export function getShopThali(data) {
  return request({
    url: '/shop/shop/thali/detail',
    method: 'get',
    params: data
  })
}

// 新增开店套餐
export function addShopThali(data) {
  return request({
    url: '/shop/shop/thali/add',
    method: 'post',
    data: data
  })
}

// 修改开店套餐
export function updateShopThali(data) {
  return request({
    url: '/shop/shop/thali/edit',
    method: 'post',
    data: data
  })
}

// 删除开店套餐
export function delShopThali(data) {
  return request({
    url: '/shop/shop/thali/delete',
    method: 'post',
    data: data
  })
}
