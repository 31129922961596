<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item :label="$t('开店套餐.套餐类型')" prop="type">
        <a-radio-group v-model="form.type" button-style="solid">
          <a-radio-button v-for="(item, index) in customDict.ShopThaliTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item :label="$t('开店套餐.套餐金额')" prop="money">
        <a-input v-model="form.money" @change="limitPrice()" suffix="¥/年"
                 :placeholder="$t('通用.输入.请输入')+$t('开店套餐.套餐金额')"/>
      </a-form-model-item>

      <a-form-model-item :label="$t('开店套餐.最大商品添加数量')" prop="num">
        <a-input v-model="form.num" @change="limitWeight()" suffix="个"
                 :placeholder="$t('通用.输入.请输入')+$t('开店套餐.最大商品添加数量')"/>
      </a-form-model-item>

      <a-form-model-item :label="$t('开店套餐.平台抽成比例')" prop="platformRatio">
        <a-input v-model="form.platformRatio" @change="limitWeight()" suffix="%"
                 :placeholder="$t('通用.输入.请输入')+$t('开店套餐.平台抽成比例')"/>
      </a-form-model-item>
      <a-form-model-item :label="this.$t('开店套餐.备注')" prop="acName" >
        <a-input v-model="form.remark" :maxLength="256" :placeholder="$t('通用.输入.请输入')+$t('开店套餐.备注')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getShopThali, addShopThali, updateShopThali} from '@/api/shop/shopThali'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
  },
  data() {
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        name: null,

        type: null,

        money: null,

        num: null,

        platformRatio: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        platformRatio: [
          {required: true, message: this.$t('开店套餐.平台抽成比例') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        num: [
          {required: true, message: this.$t('开店套餐.最大商品添加数量') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        money: [
          {required: true, message: this.$t('开店套餐.套餐金额') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('开店套餐.套餐名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        type: [
          {required: true, message: this.$t('开店套餐.套餐类型') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    limitPrice() {
      // console.log(i);
      let val = this.form.platformRatio.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 50) {
        val = 50
      }
      this.form.platformRatio = val
    },
    /*x限制只能输入数字,并限定最大值*/
    limitWeight() {
      // console.log(i);
      let val = this.form.weight.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ""
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.weight = val
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        type: null,
        money: null,
        num: null,
        platformRatio: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShopThali({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateShopThali(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addShopThali(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
